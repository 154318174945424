"use strict";

/**
 * Purpose: To shake the contact form when the user click left to right.
 *   Requires: jquery.js & velocity.js.
 */
var elements_shake_me = document.getElementsByClassName("shake_me");

var function_shake_me = function () {
  $(this).velocity("callout.shake"); // velocity.js method.
};

// Attach the function to the elements.
for (var i = 0; i < elements_shake_me.length; i++) {
  elements_shake_me[i].addEventListener("click", function_shake_me, false);
}
