'use strict';

class Messages {
  constructor() {}

  async getResponseData(results) {
    /*
     * Purpose: To extract any data from the response.
     */
    var data = undefined;
    if ((await this.isValidResponseMsg(results)) == 1) {
      if (results.hasOwnProperty('data')) {
        data = results['data'];
      }
    }
    return data;
  }

  async getDefaultReturnResults() {
    var success = 1; // Assume success from the beginning.
    var message = '';
    var status = { code: success, message: message };
    var data = {};
    var ret_results = { data: data, status: status };

    return ret_results;
  }

  async getResponseMsg(results) {
    /*
     * Purpose: To extract any status message from the response.
     */
    var message = '';
    if ((await this.isValidResponseMsg(results)) == 1) {
      if (results['status'].hasOwnProperty('message')) {
        message = results['status']['message'];
      }
    }

    return message;
  }

  async isValidResponseMsg(results) {
    /*
     * Purpose: To check if the response is a success or failure.

        Example:

            if (await this.isValidResponseMsg(results)) {
                // Got a valid message response.
                // Now can test for success or failure if want.
                isSuccess(results) {}
            } else {
                // Got an INvalid message response.
            }

     */

    var isSuccess = 0;
    if (results.hasOwnProperty('status')) {
      if (results['status'].hasOwnProperty('code')) {
        isSuccess = 1;
      }
    }

    return isSuccess;
  }

  async isSuccess(results) {
    /*
        * Purpose: To check if the response is a success or failure.
    
            Example:
    
                if (await this.isSuccess(results)) {                    
                    // success.
                } else {
                    // failure.
                }
    
    */
    var isSuccess = 0;
    if ((await this.isValidResponseMsg(results)) == 1) {
      // Check for a valid message response.
      isSuccess = results['status']['code'];
    }

    return isSuccess;
  }
}

window.messages = new Messages(); // Front-end.
//export default Messages; // Back-end.
