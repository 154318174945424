// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-right-10px {
  padding-right: 10px;
}

.padding-right-20px {
  padding-right: 20px;
}

.padding-right-30px {
  padding-right: 30px;
}

.padding-right-40px {
  padding-right: 40px;
}

.padding-right-50px {
  padding-right: 50px;
}

.padding-left-10px {
  padding-left: 10px;
}

.padding-left-20px {
  padding-left: 20px;
}

.padding-left-30px {
  padding-left: 30px;
}

.padding-left-40px {
  padding-left: 40px;
}

.padding-left-50px {
  padding-left: 50px;
}`, "",{"version":3,"sources":["webpack://./src/css/padding.css"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".padding-right-10px {\r\n  padding-right: 10px;\r\n}\r\n\r\n.padding-right-20px {\r\n  padding-right: 20px;\r\n}\r\n\r\n.padding-right-30px {\r\n  padding-right: 30px;\r\n}\r\n\r\n.padding-right-40px {\r\n  padding-right: 40px;\r\n}\r\n\r\n.padding-right-50px {\r\n  padding-right: 50px;\r\n}\r\n\r\n.padding-left-10px {\r\n  padding-left: 10px;\r\n}\r\n\r\n.padding-left-20px {\r\n  padding-left: 20px;\r\n}\r\n\r\n.padding-left-30px {\r\n  padding-left: 30px;\r\n}\r\n\r\n.padding-left-40px {\r\n  padding-left: 40px;\r\n}\r\n\r\n.padding-left-50px {\r\n  padding-left: 50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
