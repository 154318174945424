// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    For specifying alignment of elements.
*/
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.center-vertical {
  /* Vertically align a div. */
  display: -webkit-flex; /* Safari */
  display: flex;
  -webkit-align-items: center; /* Safari 7.0+ */
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.center-horizontal {
  width: 100%;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/css/alignment.css"],"names":[],"mappings":"AAAA;;CAAA;AAIA;EACE,WAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,4BAAA;EACA,qBAAA,EAAA,WAAA;EACA,aAAA;EACA,2BAAA,EAAA,gBAAA;EACA,mBAAA;EACA,+BAAA;EACA,uBAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AAAF","sourcesContent":["/*\r\n    For specifying alignment of elements.\r\n*/\r\n\r\n.float-left {\r\n  float: left;\r\n}\r\n\r\n.float-right {\r\n  float: right;\r\n}\r\n\r\n.center-vertical {\r\n  /* Vertically align a div. */\r\n  display: -webkit-flex; /* Safari */\r\n  display: flex;\r\n  -webkit-align-items: center; /* Safari 7.0+ */\r\n  align-items: center;\r\n  -webkit-justify-content: center;\r\n  justify-content: center;\r\n}\r\n\r\n.center-horizontal {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
