// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
    For specifying heights, widths, various sizes of HTML elements.    
*/
.height-auto {
  height: auto;
}

.height-20px {
  height: 20px;
}

.height-25px {
  height: 25px;
}

.height-30px {
  height: 30px;
}

.height-35px {
  height: 35px;
}

.height-40px {
  height: 40px;
}

.height-45px {
  height: 45px;
}

.height-45px {
  height: 45px;
}

.height-50px {
  height: 50px;
}

.height-55px {
  height: 55px;
}

.height-60px {
  height: 60px;
}

.height-65px {
  height: 65px;
}

.height-70px {
  height: 70px;
}

.height-75px {
  height: 75px;
}

.height-100-percent {
  height: 100%;
}

.height-100-percent {
  height: 50%;
}

.width-100-percent {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/sizes.css"],"names":[],"mappings":"AAAA;;CAAA;AAIA;EACE,YAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAEA;EACE,YAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,YAAA;AAKF;;AAHA;EACE,YAAA;AAMF;;AAJA;EACE,YAAA;AAOF;;AALA;EACE,YAAA;AAQF;;AANA;EACE,YAAA;AASF;;AAPA;EACE,YAAA;AAUF;;AARA;EACE,YAAA;AAWF;;AATA;EACE,YAAA;AAYF;;AATA;EACE,YAAA;AAYF;;AAVA;EACE,WAAA;AAaF;;AAVA;EACE,WAAA;AAaF","sourcesContent":["/*\r\n    For specifying heights, widths, various sizes of HTML elements.    \r\n*/\r\n\r\n.height-auto {\r\n  height: auto;\r\n}\r\n\r\n.height-20px {\r\n  height: 20px;\r\n}\r\n.height-25px {\r\n  height: 25px;\r\n}\r\n.height-30px {\r\n  height: 30px;\r\n}\r\n.height-35px {\r\n  height: 35px;\r\n}\r\n.height-40px {\r\n  height: 40px;\r\n}\r\n.height-45px {\r\n  height: 45px;\r\n}\r\n.height-45px {\r\n  height: 45px;\r\n}\r\n.height-50px {\r\n  height: 50px;\r\n}\r\n.height-55px {\r\n  height: 55px;\r\n}\r\n.height-60px {\r\n  height: 60px;\r\n}\r\n.height-65px {\r\n  height: 65px;\r\n}\r\n.height-70px {\r\n  height: 70px;\r\n}\r\n.height-75px {\r\n  height: 75px;\r\n}\r\n\r\n.height-100-percent {\r\n  height: 100%;\r\n}\r\n.height-100-percent {\r\n  height: 50%;\r\n}\r\n\r\n.width-100-percent {\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
