// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*------------------------------------------------------------------------*/
/* BEGIN OF: Horizontal Line Before and After Text.	*/
.HorizLineBeforeAfterText {
  overflow: hidden;
  text-align: center;
}

.HorizLineBeforeAfterText:before,
.HorizLineBeforeAfterText:after {
  background-color: lightgray;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 30%;
}

.HorizLineBeforeAfterText:before {
  right: 0.5em;
  margin-left: 0%;
}

.HorizLineBeforeAfterText:after {
  left: 0.5em;
  margin-right: 0%;
}

/* END OF: Horizontal Line Before and After Text.	*/
/*------------------------------------------------------------------------*/`, "",{"version":3,"sources":["webpack://./src/css/horiz-line-before-after-text.css"],"names":[],"mappings":"AAAA,2EAAA;AACA,qDAAA;AACA;EACC,gBAAA;EACA,kBAAA;AACD;;AACA;;EAEC,2BAAA;EACA,WAAA;EACA,qBAAA;EACA,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,UAAA;AAED;;AAAA;EACC,YAAA;EACA,eAAA;AAGD;;AADA;EACC,WAAA;EACA,gBAAA;AAID;;AAFA,mDAAA;AACA,2EAAA","sourcesContent":["/*------------------------------------------------------------------------*/\t\r\n/* BEGIN OF: Horizontal Line Before and After Text.\t*/\t\t\r\n.HorizLineBeforeAfterText {\r\n\toverflow: hidden;\r\n\ttext-align: center;\r\n}\r\n.HorizLineBeforeAfterText:before,\r\n.HorizLineBeforeAfterText:after {\r\n\tbackground-color: lightgray;\r\n\tcontent: \"\";\r\n\tdisplay: inline-block;\r\n\theight: 1px;\r\n\tposition: relative;\r\n\tvertical-align: middle;\r\n\twidth: 30%;\r\n}\r\n.HorizLineBeforeAfterText:before {\r\n\tright: 0.5em;\r\n\tmargin-left: 0%;\r\n}\r\n.HorizLineBeforeAfterText:after {\r\n\tleft: 0.5em;\r\n\tmargin-right: 0%;\r\n}\r\n/* END OF: Horizontal Line Before and After Text.\t*/\t\t\r\n/*------------------------------------------------------------------------*/\t\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
