// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  For specifying borders.
*/
.border-none {
  border: none;
  outline: none;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0 0px 0px 0;
}

.border-bottom-white {
  border-bottom: 1px solid white;
}`, "",{"version":3,"sources":["webpack://./src/css/borders.css"],"names":[],"mappings":"AAAA;;CAAA;AAIA;EACE,YAAA;EACA,aAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,0BAAA;AAAF;;AAGA;EACE,8BAAA;AAAF","sourcesContent":["/*\r\n  For specifying borders.\r\n*/\r\n\r\n.border-none {\r\n  border: none;\r\n  outline: none;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  border-radius: 0 0px 0px 0;\r\n}\r\n\r\n.border-bottom-white {\r\n  border-bottom: 1px solid white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
